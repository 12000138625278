import * as THREE from 'three'
import { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useCursor, MeshReflectorMaterial, Image, Lightformer, Environment, Hud, Text, useGLTF, OrbitControls, Float } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing } from 'maath'
import getUuid from 'uuid-by-string'
import App2 from './App2'

const GOLDENRATIO = 1


let App = null
export default App = ({ images },{ ready }) => (
  <>
  <Suspense fallback={<h1>Loading...</h1>}>
  <Canvas ready={ready} dpr={[1, 2]} camera={{ fov: 70, position: [0, 2, 15] }}>
    <color attach="background" args={['#191920']} />
    <hemisphereLight intensity={1} />
    <pointLight intensity={1} position={[1, 1, 3]} />
    <pointLight intensity={1} position={[-1, 1, 3]} />
    {/* <Environment resolution={512}>
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />
      </Environment> */}
    <fog attach="fog" args={['#191920', 0, 15]} />
    <group position={[0, -0.5, 0]}>
      <Frames images={images} />
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial
          blur={[300, 100]}
          resolution={2048}
          mixBlur={1}
          mixStrength={50}
          roughness={1}
          depthScale={1.2}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.4}
          color="#050505"
          metalness={0.5}
        />
      </mesh>
    </group>
    {/* <Environment preset="studio" /> */}
    <Hud>
      <App2 />
    </Hud>
    <Text font="/font1.otf" color="#6b6b6b" scale={4} position={[0, 2.5, -5]} rotation={[0, 0, 0]} lineHeight={1} textAlign="center" anchorX="center" anchorY="middle">
    {`STEFANO
BOMBARDIERI`}
    </Text>
    <Text font="/font1.otf" color="#ffffff" scale={0.2} position={[0, -0.43, 2]} rotation={[0, 0, 0]} lineHeight={1} textAlign="center" anchorX="center" anchorY="middle">
    {`149/333`}
    </Text>
    <Model scale={0.1} position={[0, -0.15, 1.8]} />
    <Twitter />
    <Mint/>
    <Opensea/>
    {/* <OrbitControls /> */}
  </Canvas>
  </Suspense>
   </>
)

function Twitter({ props }) {
  const [hovered1, hover1] = useState(false)
  const group = useRef()
  const { nodes } = useGLTF("/twitter.gltf");
return (
  <group ref={group} {...props} dispose={null}>
        <group position={[-0.35, -0.5, 4]} scale={0.05}>
        <Float
        speed={3}
        rotationIntensity={5}
        floatIntensity={1.5} 
        floatingRange={[1, 2]}> 
          <mesh
            name="button"
            castShadow
            receiveShadow
            geometry={nodes.twitter_logo.geometry}
            rotation={[-Math.PI /-1.5, 0, 0]}
            scale={[0.01, 0.01, 0.02]}
            onClick={(e) => window.open('https://twitter.com/bombardieri_eth')}
            onPointerOver={(event) => hover1(true)}
            onPointerOut={(event) => hover1(false)}>
            <meshStandardMaterial color={hovered1 ? 'orange' : '#47fffc'}/>
          </mesh>
          </Float>
        </group>
    </group>
  );
}
useGLTF.preload("/twitter.gltf");



function Opensea({ props }) {
  const [hovered1, hover1] = useState(false)
  const group = useRef()
  const { nodes } = useGLTF("/opensea.gltf");
return (
  <group ref={group} {...props} dispose={null}>
    <group position={[0.35, -0.8, 4]} scale={0.15}>
      <Float
          speed={3}
          rotationIntensity={5}
          floatIntensity={1.5} 
          floatingRange={[1.7, 2]}> 
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve001.geometry}
          material={nodes.Curve001.material}
          position={[0.02, 0.04, -0.01]}
          rotation={[1, 0, 0]}
          scale={2.76}
          onClick={(e) => window.open('https://opensea.io/collection/the-hanging-rhino')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshStandardMaterial color={hovered1 ? 'orange' : '#47fffc'}/>
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve002.geometry}
          material={nodes.Curve002.material}
          position={[0.02, 0.04, -0.01]}
          rotation={[1, 0, 0]}
          scale={2.76}
          onClick={(e) => window.open('https://opensea.io/collection/the-hanging-rhino')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshStandardMaterial color={hovered1 ? 'orange' : '#47fffc'}/>
        </mesh>
      </Float>
    </group>
  </group>
);
}

useGLTF.preload("/opensea.gltf");




function Mint({ props }) {
  const [hovered1, hover1] = useState(false)
  const group = useRef()
  const { nodes } = useGLTF("/button.gltf");
return (
  <group ref={group} {...props} dispose={null}>
    <group name="Scene" position={[0, -0.42, 4]} scale={0.05} rotation={[1, 0, 0]} >
      <Float
          speed={3}
          rotationIntensity={2}
          floatIntensity={1.5} 
          floatingRange={[1.7, 2]}> 
        <mesh
          name="buttonbase"
          castShadow
          receiveShadow
          geometry={nodes.buttonbase.geometry}
          position={[-0.01, 0.25, 0]}
          rotation={[0, 0.52, 0]}
          scale={0.01}
          onClick={(e) => window.open('https://www.stefanobombardieri.shop/')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshStandardMaterial color={hovered1 ? '#6b6b6b' : '#6b6b6b'}/>
        </mesh>
        <mesh
          name="button"
          castShadow
          receiveShadow
          geometry={nodes.button.geometry}
          position={[-0.01, 0.25, 0]}
          rotation={[0, 0.52, 0]}
          scale={0.01}
          onClick={(e) => window.open('https://www.stefanobombardieri.shop/')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshStandardMaterial color={hovered1 ? 'orange' : '#47fffc'}/>
        </mesh>
        <mesh
          name="Text"
          castShadow
          receiveShadow
          geometry={nodes.Text.geometry}
          position={[0.01, 0.9, 0.4]}
          scale={1.2}
          onClick={(e) => window.open('https://www.stefanobombardieri.shop/')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshStandardMaterial color={hovered1 ? '#6b6b6b' : '#6b6b6b'}/>
        </mesh>
      </Float>
    </group>
  </group>
);
}

useGLTF.preload("/button.gltf");







// export function List(props) {
//   const { nodes, materials } = useGLTF("/list.gltf");
//   const [hovered2, hover2] = useState(false)
//   const group = useRef()
//   return (
//     <group {...props} dispose={null} position={[0.2, -0.4, 4]} scale={0.15}>
//               <Float
//         speed={2}
//         rotationIntensity={5}
//         floatIntensity={1.5} 
//         floatingRange={[0.1, 0.3]}> 
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.foglio.geometry}
//         scale={[0.47, 0.61, 0.01]}
//         onClick={(e) => window.open('https://www.canva.com/design/DAFZyaW9glw/FydJtD-8oWhlQd-x-wDE-w/view?utm_content=DAFZyaW9glw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink')}
//         onPointerOver={(event) => hover2(true)}
//         onPointerOut={(event) => hover2(false)}>
//           <meshStandardMaterial color={hovered2 ? 'orange' : '#47fffc'}/>
//       </mesh>
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.Text.geometry}
//         material={materials.List}
//         position={[-0.33, 0.3, 0]}
//         rotation={[Math.PI / 2, 0, 0]}
//         scale={0.21}
//         onClick={(e) => window.open('https://www.canva.com/design/DAFZyaW9glw/FydJtD-8oWhlQd-x-wDE-w/view?utm_content=DAFZyaW9glw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink')}
//         onPointerOver={(event) => hover2(true)}
//         onPointerOut={(event) => hover2(false)}>
//       </mesh>
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.bordo.geometry}
//         rotation={[Math.PI / 2, 0, 0]}
//         scale={0.33}
//         onClick={(e) => window.open('https://www.canva.com/design/DAFZyaW9glw/FydJtD-8oWhlQd-x-wDE-w/view?utm_content=DAFZyaW9glw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink')}
//         onPointerOver={(event) => hover2(true)}
//         onPointerOut={(event) => hover2(false)}>
//         <meshStandardMaterial color={hovered2 ? 'orange' : '#47fffc'}/>
//       </mesh>
//       </Float>
//     </group>
//   );
// }

// useGLTF.preload("/list.gltf");



// export function Buy(props) {
//   const [hovered2, hover2] = useState(false)
//   const group = useRef()
//   const { nodes } = useGLTF("/buy.gltf");
//   return (
//     <group  position={[0, -0.5, 0]} scale={0.05} ref={group} {...props} dispose={null}>
//       <Float
//         speed={3}
//         rotationIntensity={1}
//         floatIntensity={0.1} 
//         floatingRange={[1, 2]}> 
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.Torus.geometry}
//         material={nodes.Torus.material}
//         scale={[1.70360315, 1, 1]}>
//         <meshStandardMaterial color={hovered2 ? '#32fa67' : '#47fffc'}/>
//       </mesh>
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.Cube.geometry}
//         material={nodes.Cube.material}
//         position={[0, 0.27502611, 0]}
//         scale={[1.16232336, 0.03345719, 0.67354882]}
//         onPointerOver={(event) => hover2(true)}
//         onPointerOut={(event) => hover2(false)}>
//         <meshStandardMaterial color={'black'}/>
//       </mesh>
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.Text.geometry}
//         material={nodes.Text.material}
//         position={[-0.8988654, 0.31179291, 0.33865708]}>
//         <meshStandardMaterial color={hovered2 ? '#32fa67' : '#47fffc'}/>
//       </mesh>
//       </Float>
//     </group>
//   );
// }
// useGLTF.preload("/buy.gltf");



export function Model(props) {
  const { nodes, materials } = useGLTF("/catena.gltf");
  const ref = useRef()
  useFrame((state, delta) => (ref.current.rotation.y -= delta /10))
  return (
    <group {...props} dispose={null} ref={ref}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Torus051.geometry}
        material={materials["Degradable Metal.002"]}
        position={[-0.86, 39.22, 1.5]}
        rotation={[0, 0.1, -Math.PI / 2]}
        scale={0.24}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials["Leather.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_1.geometry}
        material={materials["Degradable Metal"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_2.geometry}
        // material={materials.Rhino}
        >
          <meshStandardMaterial color={'#ffa600'} metalness={1} roughness={0.25} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_3.geometry}
        material={materials.Occhi}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_4.geometry}
        material={materials["Impronta.animata"]}
      />
    </group>
  );
}
useGLTF.preload("/catena.gltf");




function Frames({ images, q = new THREE.Quaternion(), p = new THREE.Vector3() }) {
  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()
  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, 5.5)
      q.identity()
    }
  })
  useFrame((state, dt) => {
    easing.damp3(state.camera.position, p, 0.4, dt)
    easing.dampQ(state.camera.quaternion, q, 0.4, dt)
  })
  return (
    <group
      ref={ref}
      onClick={(e) => (e.stopPropagation(), setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name))}
      onPointerMissed={() => setLocation('/')}>
      {images.map((props) => <Frame key={props.url} {...props} /> /* prettier-ignore */)}
    </group>
  )
}

function Frame({ url, c = new THREE.Color(), ...props }) {
  const image = useRef()
  const frame = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = getUuid(url)
  const isActive = params?.id === name
  useCursor(hovered)
  useFrame((state, dt) => {
    image.current.material.zoom = 1 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 10
    easing.damp3(image.current.scale, [0.85 * (!isActive && hovered ? 0.85 : 1), 0.9 * (!isActive && hovered ? 0.905 : 1), 1], 0.1, dt)
    easing.dampC(frame.current.material.color, hovered ? 'orange' : 'white', 0.1, dt)
  })
  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[1, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2, 0]}>
        <boxGeometry />
        <meshStandardMaterial color="#151515" metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        <Image raycast={() => null} ref={image} position={[0, 0, 0.7]} url={url} />
      </mesh>
      {/* <Text maxWidth={0.1} anchorX="left" anchorY="top" position={[0.55, GOLDENRATIO, 0]} fontSize={0.025}>
        {name.split('-').join(' ')}
      </Text> */}
    </group>
  )
}